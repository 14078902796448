import EventEmitter from 'eventemitter3'
import { sentryException, sentryMessage } from '@/plugins/sentry'

const sendToSentry = (data) => {
  if (data instanceof Error) sentryException(data)
  else {
    try {
      sentryException(JSON.stringify(data))
    } catch (e) {
      try {
        sentryMessage(data)
      } catch (e) {
        console.error(`Error capturing error in Sentry`, e)
      }
    }
  }
}

const event = new EventEmitter()

event.success = data => {
  if (typeof data == 'string') data = {type: 'success', text: data}
  event.emit('alert', data)
}
event.warning = data => {
  if (typeof data == 'string') data = {type: 'warning', text: data}
  if (data.type != 'warning') data.type = 'warning'
  event.emit('alert', data)
}
event.error = data => {
  if (typeof data == 'string') data = {type: 'error', text: data}
  else {
    sendToSentry(data)
    if (data.message || data instanceof Error) data = {error: data}
    else data = {type: 'error', ...data}
  }
  console.error(data)
  return event.emit('alert', data)
}

export default event
export const { success, error, warning } = event

export const TRIGGER_CONFIRM_DIALOG = 'trigger_confirm_dialog'
export const TRIGGER_ACTIVATION_INSTRUCTION_DIALOG = 'trigger_activation_instruction_dialog'