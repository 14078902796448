import { delay } from '@/plugins/util'
import dayjs from '@/plugins/dayjs'

export function useDemoLogic() {

  const isDemoMode = import.meta.env.VITE_DEMO_MODE === 'true'

  const getDemoBasketStatus = async (basket) => {
    await delay(1000)
    if (!basket.order_id && dayjs(basket.expires_at).isBefore(dayjs())) return {...basket, order_id: null, declined_at: null, opened_at: null, expires_at: dayjs().add(5, 'hours').toISOString()}
    if (basket.order_id) return {...basket, order_id: null, expires_at: dayjs().subtract(1,'second').toISOString()} // Ordered -> Expired
    if (basket.opened_at && basket.declined_at) return {...basket, order_id: 5234567, declined_at: null} // Declined -> Ordered
    if (basket.opened_at) return {...basket, declined_at: dayjs()} // Viewed -> Declined
    return {...basket, opened_at: dayjs()} // Sent -> Viewed
  }

  return {
    isDemoMode,
    getDemoBasketStatus
  }
}