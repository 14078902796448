import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useCustomerStore } from '@/stores/customer'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      alias: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue')
    },
    {
      path: '/search',
      name: 'search',
      component: () => import('@/views/SearchView.vue')
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/views/SupportView.vue'),
      beforeEnter: () => {
        if (!useCustomerStore().getCustomer) return {name: 'search'}
      }
    },
    {
      path: '/sales',
      name: 'sales',
      component: () => import('@/views/SalesView.vue'),
      beforeEnter: () => {
        if (!useCustomerStore().getCustomer) return {name: 'search'}
      }
    },
    {
      path: '/ticket',
      name: 'ticket',
      component: () => import('@/views/TicketView.vue'),
      beforeEnter: () => {
        if (!useCustomerStore().getCustomer) return {name: 'search'}
      }
    },
    {
      path: '/admin',
      name: 'admin',
      component: () => import('@/views/AdminView.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      redirect: (to) => ({ name: 'search' })
    }
  ]
})

router.beforeEach((to, from, next) => {
  const userStore = useUserStore()
  if (to.name !== 'login' && !userStore.loggedIn)
    next({ name: 'login', query: { error: 'notLoggedIn', redirect: to.fullPath } })
  else if (to.name == 'admin' && !userStore.isAdmin)
    next({ name: 'search', query: { error: 'notAdmin' } })
  else next()
})

export default router
