import { defineStore } from 'pinia'
import event from '@/plugins/events'
import { useSalesEligibility } from '@/use/salesEligibility'
import { useHpPartnerLogic } from '@/use/hpPartnerLogic'

const { localStorage, sessionStorage } = window

const itemTemplate = {
  offer_component_id: null,
  offer_configuration_id: null,
  transaction_type: null,
  product_size: null,
  initial_term: null,
  price: null,
  msrp: null,
  renewal_term: null,
  renewal_price: null,
  cart_id: null,
  master_cart_id: null,
  bundle_cart_id: null,
  bundle_product: null,
  bundle_size: null,
  bundle_term: null,
  bundle_promo_code: null,
  bundle_offer_configuration_id: null,
  promo_code: null
}

export const useSalesStore = defineStore('sales', {
  state: () => ({
    cart: JSON.parse(sessionStorage.getItem('SalesCart') || '{}'),
    maxId: sessionStorage.getItem('SalesCartMaxId') || 0,
    basket: JSON.parse(sessionStorage.getItem('SalesBasket') || null),
    products: JSON.parse(localStorage.getItem('Products') || '[]'),
    bundles: JSON.parse(localStorage.getItem('Bundles') || '[]'),
    hpProducts: JSON.parse(localStorage.getItem('HP_Products') || '[]'),
    hpBundles: JSON.parse(localStorage.getItem('HP_Bundles') || '[]'),
    benefits: JSON.parse(localStorage.getItem('Benefits') || '{}'),
    salesTransition: sessionStorage.getItem('SalesTransition'),
    salesOfferId: sessionStorage.getItem('SalesOfferId'),
    noOfferReason: sessionStorage.getItem('NoOfferReason'),
    overlappingProducts: JSON.parse(localStorage.getItem('OverlappingProducts') || null),
    productNameConverter: JSON.parse(localStorage.getItem('ProductNameConverter') || null),
    productAcronymConverter: JSON.parse(localStorage.getItem('ProductAcronymConverter') || null),
    productUpgrades: JSON.parse(localStorage.getItem('ProductUpgrades') || null),
    promoCode: sessionStorage.getItem('PromoCode')
  }),
  getters: {
    getCart: ({cart}) => cart,
    getCartList: ({cart}) => Object.values(cart),
    getCartCount: ({cart}) => Object.keys(cart).length,
    getBasket: ({basket}) => basket,
    getBasketToken: ({basket}) => basket?.token,
    getProducts: ({products, hpProducts}) => {
      if (useHpPartnerLogic().isHpCustomer.value) return hpProducts
      return products
    },
    getBundles: ({bundles, hpBundles}) => {
      if (useHpPartnerLogic().isHpCustomer.value) return hpBundles
      return bundles
    },
    getHpProducts:({ hpProducts }) => hpProducts,
    getHpBundles: ({ hpBundles }) => hpBundles,
    getProductUpgrades: ({productUpgrades}) => productUpgrades,
    getStandardPrice: ({cart}) => parseFloat(Object.values(cart).reduce((sum, c) => sum += (parseFloat(c.price) || 0), 0).toFixed(2)),
    getTotalMSRP: ({cart}) => parseFloat(Object.values(cart).reduce((total, c) => total += (parseFloat(c.msrp) || 0), 0).toFixed(2)),
    getBenefits: ({benefits}) => benefits,
    haveBenefits: ({benefits}) => Object.keys(benefits).length,
    getSalesTransition: ({salesTransition}) => salesTransition,
    getSalesOfferId: ({salesOfferId}) => salesOfferId,
    getNoOfferReason: ({noOfferReason}) => noOfferReason,
    getPromoCode: ({promoCode}) => promoCode
  },
  actions: {
    findProduct(sub) {
      const {getSalesProductCategory} = useSalesEligibility()
      let pc = getSalesProductCategory(sub)
      let item = this.getProducts.find(p => p.transaction_type == (sub.transaction_type == 'Bundle' ? 'Purchase' : sub.transaction_type)
                                          && ((sub.offer_configuration_id && sub.offer_configuration_id == p.offer_configuration_id)
                                            || (!sub.offer_configuration_id && p.product_category == pc
                                              && p.initial_term == sub.initial_term
                                              && p.product_size == sub.product_size)))
      if (!item) {
        let msg = `No matching product found for cart entry: ${JSON.stringify(sub)}`
        event.error(msg)
        throw msg
      }
      return {...item}
    },
    addItem(sub) {
      // console.log('Adding item to cart', sub)
      if (!sub) return
      let item = this.findProduct(sub)
      if (!item) return
      let cart_id = ++this.maxId
      const tmp = {}
      const {transaction_type, order_id, product_id} = sub
      tmp[cart_id] = {cart_id, ...item, transaction_type, stop_subscription_id: item.stop_subscription ? `${order_id}.${product_id}` : null}
      // console.log(1, tmp[cart_id])
      if (sub.transaction_type == 'Bundle') {
        item = this.findProduct({
          transaction_type: 'Bundle', product_category: sub.bundle_product,
          product_size: sub.bundle_size, initial_term: sub.bundle_term
        })
        if (!item) return
        item.master_cart_id = cart_id
        let bundle_cart_id = ++this.maxId
        tmp[cart_id].bundle_cart_id = bundle_cart_id
        item.cart_id = bundle_cart_id
        item.transaction_type = transaction_type
        tmp[bundle_cart_id] = item
        // console.log(2, tmp[bundle_cart_id])
        sub.bundle_cart_id = bundle_cart_id
        sub.bundle_offer_configuration_id = item.offer_configuration_id
        const bundleCount = this.bundles.length
        for (let i = 0; i < bundleCount; i++) {
          const b = this.bundles[i]
          const prodCount = b.products.length
          let found = 0
          for (let x = 0; x < prodCount; x++) {
            if ([tmp[cart_id].offer_configuration_id, tmp[bundle_cart_id].offer_configuration_id].includes(b.products[x].offer_configuration_id)) found++
          }
          if (found == prodCount) {
            // console.log('Found Bundle', b)
            sub.bundle_promo_code = b.promo_code
            tmp[cart_id].promo_code = b.promo_code
            tmp[bundle_cart_id].promo_code = b.promo_code
            this.setPromoCode(b.promoCode)
            break
          }
        }
        if (!sub.bundle_promo_code) {
          console.error(`Bundle not found for ${tmp[cart_id].offer_configuration_id}, ${tmp[bundle_cart_id].bundle_offer_configuration_id}`)
        }
      }
      this.cart = Object.assign(this.cart, tmp)
      sub = Object.assign(sub, tmp[cart_id])
      this.saveCart()
      return cart_id
    },
    updateItem(item={}) {
      if (!item.transaction_type) return
      // console.log(`Updating cart item`, item)
      if (!item.cart_id) return console.error(`Attempted to update Sales Cart store with an invalid item`, item)
      let offer = this.findProduct(item)
      this.cart[item.cart_id] = Object.assign(this.cart[item.cart_id], offer)
      item = Object.assign(item, this.cart[item.cart_id])
      if (item.bundle_cart_id) {
        let bun = this.findProduct({
          transaction_type: 'Bundle', product_category: item.bundle_product,
          product_size: item.bundle_size, initial_term: item.bundle_term
        })
        this.cart[item.bundle_cart_id] = Object.assign(this.cart[item.bundle_cart_id], bun)
      }
      this.saveCart()
    },
    removeItem(sub) {
      if (!sub || !sub.cart_id) return
      delete this.cart[sub.cart_id]
      if (sub.bundle_cart_id) delete this.cart[sub.bundle_cart_id]
      this.saveCart()
      Object.keys(itemTemplate).forEach(k => sub[k] = itemTemplate[k])
    },
    saveCart() {
      sessionStorage.setItem('SalesCart', JSON.stringify(this.cart))
      sessionStorage.setItem('SalesCartMaxId', this.maxId)
    },
    setProducts(products) {
      this.products = products
      products ? localStorage.setItem('Products', JSON.stringify(this.products)) : localStorage.removeItem('Products')
    },
    setBundles(bundles) {
      this.bundles = bundles
      bundles ? localStorage.setItem('Bundles', JSON.stringify(this.bundles)) : localStorage.removeItem('Bundles')
    },
    setHpProducts(products) {
      this.hpProducts = products
      products ? localStorage.setItem('HP_Products', JSON.stringify(this.hpProducts)) : localStorage.removeItem('HP_Products')
    },
    setHpBundles(bundles) {
      this.hpBundles = bundles
      bundles ? localStorage.setItem('HP_Bundles', JSON.stringify(this.hpBundles)) : localStorage.removeItem('HP_Bundles')
    },
    setBenefits(benefits) {
      if (benefits) {
        const final = {}
        benefits.forEach(b => final[b.product_category] = b.benefits)
        this.benefits = Object.assign({}, final)
        localStorage.setItem('Benefits', JSON.stringify(this.benefits))
      } else {
        this.benefits = Object.assign({}, {})
        localStorage.removeItem('Benefits')
      }
    },
    getProductBenefits(product_category) { return this.benefits[product_category] },
    setBasket(basket) {
      if (basket) {
        console.log(`New Basket`, basket)
        this.basket = Object.assign({}, basket)
        sessionStorage.setItem('SalesBasket', JSON.stringify(this.basket))
      } else {
        this.basket = null
        sessionStorage.removeItem('SalesBasket')
      }
    },
    setSalesTransition(val) {
      if (val) {
        this.salesTransition = val
        sessionStorage.setItem('SalesTransition', val)
      } else {
        this.salesTransition = null
        sessionStorage.removeItem('SalesTransition')
      }
    },
    setSalesOfferId(val) {
      if (val) {
        this.salesOfferId = val
        sessionStorage.setItem('SalesOfferId', val)
      } else {
        this.salesOfferId = null
        sessionStorage.removeItem('SalesOfferId')
      }
    },
    setNoOfferReason(val) {
      if (val) {
        this.noOfferReason = val
        sessionStorage.setItem('NoOfferReason', val)
      } else {
        this.noOfferReason = null
        sessionStorage.removeItem('NoOfferReason')
      }
    },
    getOverlappingProducts() {
      if (this.overlappingProducts) return this.overlappingProducts
      const o = {
        LiveTech: ['LiveTech with Dark Web','System Mechanic Pro & LiveTech','System Mechanic Standard & LiveTech','System Mechanic Ultimate Defense & LiveTech','Support','SmartSupport','SDCTS'],
        'System Mechanic Ultimate Defense': ['System Mechanic Standard','System Mechanic Standard & Hitman Pro','System Mechanic Standard & IPS','System Mechanic Standard & LiveTech',
                                              'System Mechanic Standard & NovaBACKUP','System Mechanic Standard & Search and Recover','System Mechanic Standard & System Shield','System Mechanic Pro',
                                              'System Mechanic Pro & IPS','System Mechanic Pro & LiveTech','System Mechanic Business','System Mechanic Business & DriveScrubber','System Mechanic Premium',
                                              'System Mechanic Ultimate Defense & LiveTech','Malware Killer','System Shield','IPS & System Shield','VirusFix','Antivirus','Antimalware',
                                              'System Mechanic Total Care', 'Hitman Pro', 'FixMeStick', 'Bitdefender', 'Live Antivirus', 'Live Antimalware', 'Live Antivirus & Antimalware', 'Live Antivirus & EasyBackupPro'],
        'System Mechanic Standard': ['System Mechanic Ultimate Defense','System Mechanic Standard & Hitman Pro','System Mechanic Standard & IPS','System Mechanic Standard & LiveTech','System Mechanic Standard & NovaBACKUP',
                                      'System Mechanic Standard & Search and Recover','System Mechanic Standard & System Shield','System Mechanic Total Care','System Mechanic Pro',
                                      'System Mechanic Pro & IPS','System Mechanic Pro & LiveTech','System Mechanic Business','System Mechanic Business & DriveScrubber','System Mechanic Premium',
                                      'System Mechanic Ultimate Defense & LiveTech','MyCleanPC','MyCleanMac'],
        WarrantyStar: [],
        GetMyDrivers: [],
        IDProtect: ['SAS ID Protect', 'Identity Force'],
        MyCleanID: ['Privacy Guardian'],
        OnlineBackup: ['EasyBackupPro'],
        VPN: ['HotspotShield'],
      }
      for (const k in o) {
        const oCount = o[k].length
        for (let i = 0; i < oCount; i++) {
          o[o[k][i]] = o[k]
          o[o[k][i]].push(k)
        }
      }
      this.overlappingProducts = o
      localStorage.setItem('OverlappingProducts', JSON.stringify(this.overlappingProducts))
      return this.overlappingProducts
    },
    getProductNameConverter() {
      if (this.productNameConverter) return this.productNameConverter
      this.productNameConverter = {
        'LiveTech': 'LT', 'System Mechanic Ultimate Defense': 'SMUD', 'System Mechanic Standard': 'SMS',
        'WarrantyStar': 'WS', 'GetMyDrivers': 'GMD', 'VPN': 'VPN',
        'IDProtect': 'IDP', 'MyCleanID': 'MCID', 'OnlineBackup': 'OB'
      }
      localStorage.setItem('ProductNameConverter', JSON.stringify(this.productNameConverter))
      return this.productNameConverter
    },
    getProductAcronymConverter() {
      if (this.productAcronymConverter) return this.productAcronymConverter
      const pn = this.getProductNameConverter()
      const p = {}
      for (const k in pn) p[pn[k]] = k
      this.productAcronymConverter = p
      localStorage.setItem('ProductAcronymConverter', JSON.stringify(this.productAcronymConverter))
      return this.productAcronymConverter
    },
    setProductUpgrades(products) {
      if (products) {
        this.productUpgrades = products
        localStorage.setItem('ProductUpgrades', JSON.stringify(this.productUpgrades))
      } else {
        this.productUpgrades = []
        localStorage.removeItem('ProductUpgrades')
      }
    },
    setPromoCode(code) {
      this.promoCode = code
      code ? sessionStorage.setItem('PromoCode', this.promoCode) : sessionStorage.removeItem('PromoCode')
    },
    getSalesProductCategories() {
      const final = {}
      this.getProducts?.forEach(p => {
        const {product_line_category, product_line, product_category} = p
        if (!final[product_line_category]) {
          final[product_line_category] = []
          final[product_line_category].push({product_line_category, product_line, product_category})
        } else if (!final[product_line_category].some(l => l.product_category == product_category)) {
          final[product_line_category].push({product_line_category, product_line, product_category})
        }
      })
      return final
    },
    clearProductData() {
      this.setProducts()
      this.setBundles()
      this.setHpProducts()
      this.setHpBundles()
      this.setBenefits()
      this.productNameConverter = null
      localStorage.removeItem('ProductNameConverter')
      this.productAcronymConverter = null
      localStorage.removeItem('ProductAcronymConverter')
      this.overlappingProducts = null
      localStorage.removeItem('OverlappingProducts')
      this.productUpgrades = null
      localStorage.removeItem('ProductUpgrades')
    },
    clearSalesOffer() {
      this.setSalesOfferId()
      this.setSalesTransition()
      this.setNoOfferReason()
      this.setPromoCode()
    },
    clearCart() {
      this.cart = Object.assign({}, {})
      this.maxId = 0
      sessionStorage.removeItem('SalesCart')
      sessionStorage.removeItem('SalesCartMaxId')
      this.setPromoCode()
    },
    clearCustomer() {
      this.setBasket()
      this.clearCart()
      this.clearSalesOffer()
    },
    clear() {
      this.clearCustomer()
      this.clearProductData()
    },
    logout() {
      this.clear()
    }
  }
})