import '@/assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import vuetifyPlugin from '@/plugins/vuetify'
import axiosPlugin from '@/plugins/axios'
import dayjsPlugin from '@/plugins/dayjs'
import { sentryInit } from '@/plugins/sentry'
import { vuetifyProTipTap } from '@/plugins/tiptap'

import App from './App.vue'
import router from './router'

const app = createApp(App)

sentryInit(app, router)

app.use(createPinia())
app.use(router)
app.use(vuetifyPlugin)
app.use(axiosPlugin)
app.use(dayjsPlugin)
app.use(vuetifyProTipTap)

app.mount('#app')
