import { defineStore } from 'pinia'
import { api } from '@/plugins/axios'
import event from '@/plugins/events'
const storage = window.localStorage

export const useUserStore = defineStore('user', {
  state: () => ({
    user: JSON.parse(storage.getItem('AgentUser') || '{}'),
    token: storage.getItem('AgentToken'),
    pinNav: storage.getItem('PinNav') == 'true'
  }),
  actions: {
    setToken(token) {
      this.token = token
      storage.setItem('AgentToken', this.token)
    },
    login(user, token) {
      this.user = Object.assign({}, user)
      storage.setItem('AgentUser', JSON.stringify(this.user))
      this.setToken(token)
    },
    async logout() {
      this.user = Object.assign({}, {})
      this.token = null
      storage.removeItem('AgentUser')
      storage.removeItem('AgentToken')
      storage.removeItem('PinNav')
      try {
        await api.delete('/login')
      } catch (e) {
        event.error(e)
      }
    },
    setUserId(user_id) {
      this.user = Object.assign(this.user, {user_id})
      storage.setItem('AgentUser', JSON.stringify(this.user))
    },
    setEmail(email) {
      this.user = Object.assign(this.user, {email})
      storage.setItem('AgentUser', JSON.stringify(this.user))
    },
    setPin(val) {
      this.pinNav = val
      storage.setItem('PinNav', this.pinNav)
    }
  },
  getters: {
    getUser: ({user}) => user,
    getUserId: ({user}) => user?.user_id,
    getUserName: ({user}) => user?.first_name ? `${user.first_name} ${user.last_name}` : null,
    getFirstName: ({user}) => user?.first_name,
    getLastName: ({user}) => user?.last_name,
    getToken: ({token}) => token,
    isAdmin: ({user}) => user?.role == 'Admin',
    getPin: ({pinNav}) => pinNav,
    loggedIn: ({token}) => token ? true : false
  }
})
