import { defineStore } from 'pinia'
import { reactive } from 'vue'
import { api } from '@/plugins/axios'
import event from '@/plugins/events'
import { formatPhoneNumber } from '@/plugins/util'
import { useSalesStore } from '@/stores/sales'
const storage = window.sessionStorage

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    customer: JSON.parse(storage.getItem('Customer') || null),
    searchSelections: [],
    searchResults: [],
    searchCriteria: {
      email: null,
      phone: null,
      first_name: null,
      last_name: null,
      order_id: null
    },
    callSessionId: storage.getItem('CallSessionId'),
    ani: storage.getItem('ANI'),
    dnis: storage.getItem('DNIS'),
    queue: storage.getItem('Queue'),
    recentOrder: storage.getItem('RecentOrder'),
    retention: JSON.parse(storage.getItem('Retention') || null),
    requireReasonForContact: JSON.parse(storage.getItem('RequireReasonForContact') || null),
    screenPopId: storage.getItem('ScreenPopId'),
    subscriptions: JSON.parse(storage.getItem('CustomerSubscriptions') || null),
    productLineCategories: ['Tech Support','Device Optimization','Data Protection','Identity Protection'],
    pushProductLines: {
      'Tech Support': ['LiveTech'],
      'Device Optimization': ['System Mechanic', 'GetMyDrivers', 'WarrantyStar'],
      'Data Protection': ['OnlineBackup', 'VPN'],
      'Identity Protection': ['MyCleanID', 'IDProtect']
    },
    askedUpdatePhone: JSON.parse(storage.getItem('AskedUpdatePhone') || false)
  }),
  actions: {
    getStickyOrderUrl(order_id) {
      return `https://${import.meta.env.VITE_STICKY_SUB_DOMAIN}.sticky.io/admin/orders.php?show_details=show_details&show_folder=view_all&fromPost=1&sequence=1&show_by_id=${order_id}`
    },
    async setCustomer(customer, foundFromPhone, fromTicketPop) {
      this.setAskedUpdatePhone()
      if (!customer) {
        this.customer = null
        storage.removeItem('Customer')
        return
      }
      this.customer = Object.assign({}, customer)
      storage.setItem('Customer', JSON.stringify(this.customer))
      if (this.getCallSessionId || foundFromPhone || fromTicketPop) {
        try {
          const logData = {customer_dim_key: customer.customer_dim_key}
          if (this.getCallSessionId) logData.call_session_id = this.getCallSessionId
          if (foundFromPhone) logData.found_from_phone = true
          if (fromTicketPop) logData.ticket_id = fromTicketPop
          if (this.getScreenPopId) logData.id = this.getScreenPopId
          const {id} = await api.post('/customers/screen_pop', logData)
          if (!this.getScreenPopId) this.setScreenPopId(id)
        } catch (e) {
          event.error(e)
        }
      }
    },
    setAskedUpdatePhone(val) {
      if (val) {
        this.askedUpdatePhone = true
        storage.setItem('AskedUpdatePhone', true)
      } else {
        storage.removeItem('AskedUpdatePhone')
        this.askedUpdatePhone = false
      }
    },
    setZendeskId(id) {
      if (this.customer) this.customer.zendesk_id = id
      if (id) storage.setItem('Customer', JSON.stringify(this.customer))
    },
    setRecentOrder(id) {
      this.recentOrder = id
      id ? storage.setItem('RecentOrder', id) : storage.removeItem('RecentOrder')
    },
    setScreenPopId(id) {
      this.screenPopId = id
      id ? storage.setItem('ScreenPopId', this.screenPopId) : storage.removeItem('ScreenPopId')
    },
    setRequireReasonForContact(val) {
      this.requireReasonForContact = val
      val ? storage.setItem('RequireReasonForContact', this.requireReasonForContact) : storage.removeItem('RequireReasonForContact')
    },
    setSearchSelections(data) { this.searchSelections = data },
    setSearchResults(data) { this.searchResults = data },
    setSearchCriteria(data) { this.searchCriteria = Object.assign({}, data) },
    setCallSessionId(id) {
      this.callSessionId = id
      id ? storage.setItem('CallSessionId', id) : storage.removeItem('CallSessionId')
    },
    setRetention(data) {
      if (data) {
        this.retention = Object.assign({}, data)
        storage.setItem('Retention', JSON.stringify(this.retention))
      } else {
        this.retention = null
        storage.removeItem('Retention')
      }
    },
    setSubscriptions(subs) {
      this.subscriptions = subs
      subs ? storage.setItem('CustomerSubscriptions', JSON.stringify(subs)) : storage.removeItem('CustomerSubscriptions')
    },
    async setQuery(query) {
      if (!query) {
        this.ani = null
        this.dnis = null
        this.queue = null
        storage.removeItem('ANI')
        storage.removeItem('DNIS')
        storage.removeItem('Queue')
        return this.setCallSessionId()
      }
      let sessionId = query.sessionId || query.callSessionId
      if (sessionId != '') {
        this.setCallSessionId(sessionId)
        this.setRequireReasonForContact(true)
      }
      if (query.phone && query.phone != '') {
        this.ani = query.phone
        storage.setItem('ANI', this.ani)
        this.setRequireReasonForContact(true)
      }
      let dnis = query.DNIS || query.dnis
      if (dnis && dnis != '') {
        this.dnis = formatPhoneNumber(dnis)
        storage.setItem('DNIS', this.dnis)
      }
      if (query.queue && query.queue != '') {
        this.queue = query.queue
        storage.setItem('Queue', this.queue)
      }
      try {
        const {callSessionId, screenPopId} = this
        const logData = {call_session_id: callSessionId, ticket_id: query.ticket_id}
        if (screenPopId) logData.id = screenPopId
        else logData.query = query
        const {id} = await api.post('/customers/screen_pop', logData)
        if (!screenPopId) this.setScreenPopId(id)
      } catch (e) {
        event.error(e)
      }
    },
    clear() {
      this.setCustomer()
      this.setQuery()
      this.setScreenPopId()
      this.setRecentOrder()
      this.setRequireReasonForContact()
      this.setSearchSelections([])
      this.setSearchResults([])
      this.setRetention()
      this.setSearchCriteria({})
      this.setSubscriptions()
    },
    logout() {
      this.clear()
    },
  },
  getters: {
    getCustomer: ({customer}) => customer,
    getCustomerDimKey: ({customer}) => customer?.customer_dim_key,
    getName: ({customer}) => `${customer?.first_name} ${customer?.last_name}`,
    getFirstName: ({customer}) => customer?.first_name,
    getLastName: ({customer}) => customer?.last_name,
    getPhone: ({customer}) => customer?.phone,
    getEmail: ({customer}) => customer?.email,
    getCountry: ({customer}) => customer?.country,
    getZendeskId: ({customer}) => customer?.zendesk_id,
    getAddress: ({customer}) => `${customer?.street || ''}${customer?.street2 ? ' ' + customer.street2 : ''}${customer?.city ? ', ' + customer.city : ''}${customer?.state || customer?.zip ? ', ' + (customer?.state || '') + ' ' + (customer?.zip || '') : ''}${customer?.country ? ', ' + customer.country : ''}`,
    getContactInfo() {
      let res = ''
      if (this.getEmail && this.getEmail != '') res += this.getEmail
      if (this.getPhone && this.getPhone != '') {
        if (res != '') res += ' - '
        res += this.getPhone
      }
      return res
    },
    getUpsellable: ({customer}) => customer?.upsellable,
    getSearchResults: ({searchResults}) => searchResults,
    getSearchSelections: ({searchSelections}) => searchSelections,
    getSearchCriteria: ({searchCriteria}) => searchCriteria,
    getCallSessionId: ({callSessionId}) => callSessionId,
    getQueue: ({queue}) => queue,
    getANI: ({ani}) => ani,
    getDNIS: ({dnis}) => dnis,
    getRetention: ({retention}) => retention,
    getRetentionLogId: ({retention}) => retention?.log_id,
    getRequireReasonForContact: ({requireReasonForContact}) => requireReasonForContact,
    getStickyUrl: ({customer}) => `https://${import.meta.env.VITE_STICKY_SUB_DOMAIN}.sticky.io/admin/orders.php?email_filter=${encodeURIComponent(customer?.email)}`,
    getZendeskUrl: ({customer}) => `https://${import.meta.env.VITE_ZENDESK_SUB_DOMAIN}.zendesk.com/agent/users/${customer?.zendesk_id}/requested_tickets`,
    checkUpdatePhone: ({customer, ani, askedUpdatePhone}) => !askedUpdatePhone && ani && customer?.phone != ani,
    getScreenPopId: ({screenPopId}) => screenPopId,
    getSubscriptions: ({subscriptions}) => subscriptions,
    getProductLineCategories: ({productLineCategories}) => productLineCategories,
    getCategorizedSubs({subscriptions}) {
      let final = {}
      if (subscriptions && subscriptions.length) {
        // Add existing subscriptions
        subscriptions.forEach((s) => {
          if (!final[s.product_line_category]) final[s.product_line_category] = []
          s.expanded = false
          s.payMethodLoading = false
          s.transaction_type = null
          s.initial_term = null
          s.product_size = null
          s.msrp = null
          s.cart_id = null
          s.offer_configuration_id = null
          final[s.product_line_category].push(s)
        })
      }
      // Add missing product lines to push in each product line category
      const { getSalesProductCategories } = useSalesStore()
      const products = getSalesProductCategories()
      this.getProductLineCategories.forEach((c) => {
        if (!final[c]) final[c] = []
        products[c]?.forEach(l => {
          final[c].push(reactive({ 
            product_line_category: l.product_line_category,
            product_line: l.product_line,
            product_category: l.product_category,
            status: 'Never',
            expanded: false,
            transaction_type: null,
            initial_term: null,
            product_size: null,
            msrp: null,
            price: null,
            offer_configuration_id: null,
            cart_id: null
          }))
        })
      })
      let ordering = {}
      let sortOrder = ['Active - AR On', 'Active - AR Off', 'Former', 'Never']
      for (var i = 0; i < 4; i++) ordering[sortOrder[i]] = i
      this.getProductLineCategories.forEach((c) => {
        final[c].sort((a, b) => {
          return (
            ordering[a.status] - ordering[b.status] ||
            a.product_line.localeCompare(b.product_category)
          )
        })
      })
      return final
    },
    getAskedUpdatePhone: ({askedUpdatePhone}) => askedUpdatePhone
  }
})
